import { NgIf, NgFor } from '@angular/common';
import { Component, Inject, Input, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslocoDirective } from '@ngneat/transloco';
import { mapToSchema } from '@sympheny/format-value';

import { DialogSummaryValueComponent } from './dialog-summary-value/dialog-summary-value.component';
import { Summary, DialogSummaryData, DialogSummaryValueConfig } from './model';
import { DialogActionsComponent } from '../dialog-actions/dialog-actions.component';

@Component({
  selector: 'sympheny-dialog-summary',
  templateUrl: './dialog-summary.component.html',
  styleUrls: ['./dialog-summary.component.scss'],
  providers: [],
  imports: [
    TranslocoDirective,
    MatDialogModule,
    NgIf,
    NgFor,
    DialogSummaryValueComponent,
    DialogActionsComponent,
  ],
})
export class DialogSummaryComponent<DATA> {
  @Input() public title!: string;
  @Input() public summaries!: Summary<DATA>[];
  @Input() public data: DATA;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) data: DialogSummaryData<DATA>,
  ) {
    if (data) {
      this.title = data.title;

      this.summaries = mapToSchema(
        data.summaryFields,
        data.fieldConfig,
        data.schema,
        {
          ...(data.additionalConfig ?? {}),
          additionalData: { params: data.params },
        },
      ) as unknown as DialogSummaryValueConfig<DATA>[];
      this.data = data.data;
    }
  }
}
