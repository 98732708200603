import { NgIf, AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  BaseMapConfig,
  DragAndDropChangeEvent,
  DragAndDropService,
} from '@sympheny/gis/utils';
import {
  Hub,
  NetworkLink,
  NetworkTechnology,
  Project,
} from '@sympheny/project/data-access';
import { LayerType } from '@sympheny/project/scenario/data-access';
import { Feature } from 'ol';
import Map from 'ol/Map';
import { Observable, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { ChangeBasemapComponent } from './change-basemap/change-basemap.component';
import { FeatureInfoComponent } from './feature-info/feature-info.component';
import { MapInfoComponent } from './map-info/map-info.component';
import { MapUtilsService } from './map-utils.service';
import { DrawNetworkLinkService } from './network-link/draw-network-link.service';
import { MapLoadDirective } from './on-load.directive';
import { SelectedBuildingInfo } from '../building-information';
import { SelectFeaturesComponent } from './select-features/select-features.component';
import { BuildingInformationComponent } from '../building-information/building-information/building-information.component';
import {
  LayerConfigurationEvent,
  LayerConfigurationComponent,
} from '../customer-layer/layer-configuration/layer-configuration.component';
import {
  NewLayerEvent,
  NewLayerConfigurationComponent,
} from '../customer-layer/new-layer-configuration/new-layer-configuration.component';
import { DrawCustomerLayersService } from '../layer/draw-customer-layers.service';
import { DrawHubService } from '../layer/draw-hub.service';
import { DrawHubsLayersService } from '../layer/draw-hubs-layers.service';
import { DrawLayerService } from '../layer/draw-layer.service';
import { DrawNetworkLayerService } from '../layer/draw-network-layer.service';
import { MapLayer } from '../layer/map-layer';
import { MapConfiguration } from '../model/configuration';
import { HubChangeEvent } from '../model/events';
import { ClickAndRetrieveBuildingInformationService } from '../services/click-and-retrieve-building-information.service';
import { SelectFeaturesService } from '../services/select-features.service';
import { MapStore } from '../store/map.store';

@Component({
  selector: 'sympheny-map-gis',
  templateUrl: './map-gis.component.html',
  styleUrls: ['./map-gis.component.scss'],
  providers: [
    DrawHubsLayersService,
    DrawCustomerLayersService,
    DrawNetworkLayerService,
    MapUtilsService,
    ClickAndRetrieveBuildingInformationService,
    SelectFeaturesService,
    {
      provide: DrawLayerService,
      useExisting: DrawCustomerLayersService,
      multi: true,
    },
    {
      provide: DrawLayerService,
      useExisting: DrawNetworkLayerService,
      multi: true,
    },
    {
      provide: DrawLayerService,
      useExisting: DrawHubsLayersService,
      multi: true,
    },
  ],
  imports: [
    MatSidenavModule,
    NgIf,
    SelectFeaturesComponent,
    FeatureInfoComponent,
    ChangeBasemapComponent,
    MapLoadDirective,
    LayerConfigurationComponent,
    NewLayerConfigurationComponent,
    BuildingInformationComponent,
    MapInfoComponent,
    AsyncPipe,
  ],
})
export class MapGisComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() public mapHeight = '100%';
  @Input() public scenarioId!: string;
  @Input() public editableHubId?: string;
  @Input() public newHub?: boolean;
  @Input() public disabled?: boolean;
  @Input() public canEdit?: boolean;
  @Input() public zoomToHub?: string;
  @Input() public energyResourceHubGuid?: string;
  @Input() public hubs?: Hub[];
  @Input() public networkLink?: NetworkLink;
  @Input() public project: Project;
  @Input() public config!: MapConfiguration;

  @Output() public readonly networkLinkChange =
    this.drawNetworkLinkService.changeDetails$;
  @Output() public readonly hubChange = new EventEmitter<HubChangeEvent>();
  @Output() public readonly selectedFeatures = new EventEmitter<
    Feature<any>[]
  >();

  public buildingInformationOpen = false;
  public layerConfigurationOpen = false;
  public buildingInfoOptions: SelectedBuildingInfo | null = null;

  public readonly selectedAddress$ =
    this.scenarioMapStore.selectValue('address');
  public readonly addressProcessed$ =
    this.scenarioMapStore.selectValue('addressProcessed');
  public readonly selectedDatasetAddress$ =
    this.scenarioMapStore.dataSetAddress$;
  public readonly selectedDataset$ =
    this.scenarioMapStore.selectValue('dataset');
  public readonly featureData$ =
    this.scenarioMapStore.selectValue('featureData');

  public newLayerConfiguration: DragAndDropChangeEvent | null = null;

  public readonly mapUuid = uuidv4();
  public readonly editableLayers$: Observable<MapLayer[]> =
    this.drawCustomerLayersService.editableLayers$;

  private map: Map;
  private destroy$ = new Subject<void>();

  constructor(
    private drawCustomerLayersService: DrawCustomerLayersService,
    private drawNetworkLinkService: DrawNetworkLinkService,
    private drawHubService: DrawHubService,
    private drawNetworkLayerService: DrawNetworkLayerService,
    private drawHubsLayersService: DrawHubsLayersService,
    private dragAndDropService: DragAndDropService,
    @Inject(DrawLayerService) private drawLayerService: DrawLayerService[],
    private scenarioMapStore: MapStore,
    private cdr: ChangeDetectorRef,
    private mapUtilsService: MapUtilsService,
    private clickService: ClickAndRetrieveBuildingInformationService,
    private readonly selectFeaturesService: SelectFeaturesService,
  ) {
    this.createBaseMap();
  }

  @HostBinding('class') public get hostClass() {
    return 'block h-full ';
  }

  public get downloadableLayers() {
    return this.drawLayerService
      .map((layer) => layer.getDownloadableLayers())
      .flat();
  }

  @Input() public set networkTechnologies(
    networkTechnologies: NetworkTechnology[] | null,
  ) {
    this.drawNetworkLayerService.setNetworkTechnologies(
      networkTechnologies ?? [],
    );
    if (!networkTechnologies) {
      return;
    }
  }

  public onOpenBuildingInfo() {
    this.buildingInformationOpen = true;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['layerConfigurationOpen']) {
      if (this.newLayerConfiguration) {
        this.layerConfigurationOpen = true;
      }
    }

    if (!this.map) {
      return;
    }

    if (changes['config']) {
      this.drawLayerService.forEach((service) =>
        service.initMap(this.map, this.config),
      );
    }

    if (
      changes['config'] ||
      changes['scenarioId'] ||
      changes['canEdit'] ||
      changes['disabled']
    ) {
      this.loadData();
    }

    if (changes['zoomToHub']) {
      this.drawHubsLayersService.zoomToHub(this.zoomToHub);
    }

    if (changes['project'] && this.project) {
      if (!this.zoomToHub) {
        this.mapUtilsService.zoomTo(this.project.getOlCoordinates?.());
      }
    }

    if (changes['networkLink']) {
      this.drawNetworkLayerService.drawNetworkLink(this.networkLink);
    }
    if (changes['energyResourceHubGuid'] || changes['editableHubId']) {
      const id = this.energyResourceHubGuid
        ? this.energyResourceHubGuid
        : this.editableHubId;
      this.drawHubsLayersService.drawHub(id!);
    }
  }

  public selectedFeaturesEmitter(features: Feature<any>[]) {
    this.selectedFeatures.emit(features);
  }

  public saveLayer(event: LayerConfigurationEvent) {
    this.scenarioMapStore.update(
      event.layerType,
      this.scenarioId,
      event.layerId,
      event.data,
    );
  }

  public createLayer(event: NewLayerEvent) {
    this.scenarioMapStore.create(event.layerType, this.scenarioId, event.data);

    this.removeDragAndDrop();
  }

  public cancelNewLayer() {
    this.removeDragAndDrop();
  }

  public deleteLayer(
    event: Pick<LayerConfigurationEvent, 'layerType' | 'layerId'>,
  ) {
    this.scenarioMapStore.delete(
      event.layerType,
      this.scenarioId,
      event.layerId,
    );
  }

  public toggleCo2Range(toggle: boolean) {
    this.scenarioMapStore.toggleCo2Range(toggle);
  }

  public loadMap() {
    this.mapUtilsService.addMapToDiv();

    if (this.zoomToHub) {
      this.drawHubsLayersService.zoomToHub(this.zoomToHub);
    } else if (this.project) {
      this.mapUtilsService.zoomTo(this.project.getOlCoordinates?.());
    } else {
      this.mapUtilsService.zoomTo(null);
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public toggleLayerConfiguration() {
    this.layerConfigurationOpen = !this.layerConfigurationOpen;
    if (this.layerConfigurationOpen) {
      this.buildingInformationOpen = false;
    }
    this.cdr.markForCheck();
  }

  public toggleBuildingInformation() {
    this.buildingInformationOpen = !this.buildingInformationOpen;
    if (this.buildingInformationOpen) {
      this.layerConfigurationOpen = false;
    }
    this.cdr.markForCheck();
  }

  public changeBaseMap(config: BaseMapConfig) {
    this.mapUtilsService.changeBaseMap(config);
  }

  public ngAfterViewInit(): void {
    this.mapUtilsService.resize();
  }

  private loadData() {
    this.destroy$.next();

    if (this.config.networkLink.edit) {
      this.drawNetworkLinkService.init(this.map);
    }
    if (this.config.hub.edit) {
      this.mapUtilsService.addMapSearchBar();

      this.drawHubService.init(this.map, (event) =>
        this.hubChange.emit({
          feature: event.geoJson.features[0],
          mapChanged: event.mapChanged,
        }),
      );

      if (this.editableHubId) {
        this.drawHubService.setHubLayer(
          this.scenarioMapStore.getLayer(LayerType.hubs, this.editableHubId),
        );
      }
    }

    if (this.config.dragAndDrop?.enabled && !this.disabled && this.canEdit) {
      this.dragAndDropService.init(this.map, (event) =>
        this.dragAndDrop(event),
      );
    }

    if (this.config.layerSwitcher) {
      this.mapUtilsService.addLayerSwitcher();
    }

    if (this.config.select.address) {
      this.clickService.init(this.map, this.scenarioId);
    }
    if (this.config.select.features) {
      this.selectFeaturesService.init(this.map, this.scenarioId, (features) =>
        this.selectedFeatures.emit(features),
      );
    }
  }
  private createBaseMap() {
    // Create Map
    this.map = this.mapUtilsService.initMap(
      this.mapUuid,
      this.scenarioMapStore.getView(),
      (layerType: LayerType, layerId: string) =>
        this.scenarioMapStore.loadLayer(layerType, layerId),
    );
  }

  private dragAndDrop(event: DragAndDropChangeEvent) {
    this.layerConfigurationOpen = true;
    this.newLayerConfiguration = event;
    this.cdr.markForCheck();
  }

  private removeDragAndDrop() {
    this.map.removeLayer(this.newLayerConfiguration?.vectorLayer);
    this.newLayerConfiguration = null;
    this.layerConfigurationOpen = false;
  }
}
