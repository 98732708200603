import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonIconComponent } from '../button-icon/button-icon.component';

@Component({
  selector: 'sympheny-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss'],
  imports: [ButtonIconComponent],
})
export class FavoriteComponent {
  @Input() public favorite = false;
  @Output() public readonly favoriteChange = new EventEmitter<boolean>();

  public toggleFavorite($event: MouseEvent): void {
    $event.preventDefault();
    this.favorite = !this.favorite;
    this.favoriteChange.emit(this.favorite);
  }
}
