import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';

import { ButtonComponent } from '../button/button.component';

export interface ButtonContainerConfig {
  submit: {
    action: () => Promise<any>;
  };
  cancel: {
    action: () => Promise<any> | void;
  };
  edit: {
    action: (edit: boolean) => Promise<any> | void;
  };
}

@Component({
  selector: 'sympheny-button-container',
  templateUrl: './button-container.component.html',
  imports: [NgIf, ButtonComponent, TranslocoPipe],
})
export class ButtonContainerComponent {
  public editing = false;
  @Input() public config: ButtonContainerConfig | null = null;

  public onEdit() {
    this.changeEdit(true);
  }

  public onCancel() {
    this.changeEdit(false);
    this.config?.cancel.action();
  }

  public onSubmit() {
    this.config?.submit.action().then?.(() => this.changeEdit(false));
  }

  private changeEdit(edit: boolean) {
    this.editing = edit;
    this.config?.edit.action(edit);
  }
}
