import { MediaMatcher } from '@angular/cdk/layout';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import {
  LangDefinition,
  TranslocoPipe,
  TranslocoService,
} from '@ngneat/transloco';
import { apiLinks } from '@sympheny/api-docs';
import { BreadcrumbComponent } from '@sympheny/ui/breadcrumb';
import { AuthService } from '@sympheny/user/auth0';
import { UserState } from '@sympheny/user/data-access';
import { Observable } from 'rxjs';

import { LanguageSwitcherComponent } from '../language-switcher/language-switcher.component';

@Component({
  selector: 'sympheny-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    BreadcrumbComponent,
    MatMenuModule,
    RouterLink,
    NgFor,
    TranslocoPipe,
    NgIf,
    LanguageSwitcherComponent,
    AsyncPipe,
    MatToolbarModule,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public readonly mobileQuery: MediaQueryList =
    this._media.matchMedia('(max-width: 600px)');
  private readonly _mobileQueryListener: () => void;
  public readonly authenticated$: Observable<boolean> =
    this._auth.isAuthenticated$;
  public readonly languages: LangDefinition[] =
    this.translocoService.getAvailableLangs() as LangDefinition[];
  public readonly currentLanguage$ = this.translocoService.langChanges$;

  public readonly apiLinks = apiLinks;

  public readonly profileImage$ = this.userState.profileImage$;
  public readonly firstLetter$ = this.userState.firstLetter$;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _auth: AuthService,
    private readonly _media: MediaMatcher,
    private readonly translocoService: TranslocoService,
    private readonly userState: UserState,
  ) {
    this._mobileQueryListener = () => this._changeDetectorRef.detectChanges();
  }

  public ngOnInit(): void {
    this.mobileQuery.addEventListener('responsive', this._mobileQueryListener);
  }

  public logout(): void {
    this.userState.logout().then(() => this._auth.logout());
  }

  public ngOnDestroy(): void {
    this.mobileQuery.removeEventListener(
      'responsive',
      this._mobileQueryListener,
    );
  }

  public changeLanguage(language: string) {
    this.translocoService.setActiveLang(language);
  }
}
