import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoPipe } from '@ngneat/transloco';
import { formatNumberToPrintFormat } from '@sympheny/utils/format';
import { fromEvent, map } from 'rxjs';

import { EhubType } from './ehub.model';
import {
  EhubArrow,
  EhubFirstColumnAllowed,
  EhubLastColumnAllowed,
  EhubLinkColor,
  EhubLinkTooltip,
  EhubMinHorizontalNodeX,
  EhubMinVerticalNodeY,
  EhubNodeColor,
  EhubNodeDimension,
  EhubNodeText,
  EhubNodeTooltip,
  EhubSortBy,
} from './ehub.utils';
import { Zoom } from './zoom.utils';
import { drawEhub } from '../utils/draw-ehub';
import { drawSankeyCirculair } from '../utils/draw-sankey';

@Component({
  selector: 'sympheny-ehub',
  templateUrl: './ehub.component.html',
  host: { class: 'w-full h-full block bg-white' },
  imports: [TranslocoPipe, NgIf, MatIconModule, AsyncPipe, CommonModule],
})
export class EhubComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public type: EhubType;
  @Input() public dataset: any;
  @Input() public ignoreZeroCapcity = false;
  @Input() public fullWidth = false;
  @Input() public drawDoubleArrows = false;

  @ViewChild('chart', { static: true })
  public readonly chart: ElementRef;
  @ViewChild('tooltip', { static: true })
  public readonly tooltip: ElementRef;
  private readonly observer: ResizeObserver;
  private svgNode: SVGElement;
  private width: number;
  private height: number;
  private dimensionsBeforeFullscreen = { width: 0, height: 0 };
  private zoom: Zoom;

  public showDiagram = false;
  public showWarning = false;
  public fullScreen = false;
  public mousePosition$ = fromEvent<MouseEvent>(
    this.elementRef.nativeElement,
    'mousemove',
  ).pipe(
    map((event) => `translate3d(${event.clientX}px, ${event.clientY}px, 0px)`),
  );

  constructor(private readonly elementRef: ElementRef) {
    this.observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      this.resize(entries[0]!.contentRect);
    });
  }

  public ngOnChanges() {
    this.showDiagram = false;
    this.showWarning = false;
    this.drawData();
  }

  public ngOnInit(): void {
    this.observer.observe(this.chart.nativeElement);
    this.zoom = new Zoom(this.chart.nativeElement);
  }

  public ngOnDestroy(): void {
    this.observer.unobserve(this.chart.nativeElement);
    this.observer.disconnect();
  }

  public toggleFullscreen() {
    if (this.fullScreen) this.exitFullScreen();
    else this.requestFullScreen();
    this.zoom.reset();
  }

  private requestFullScreen() {
    this.dimensionsBeforeFullscreen = {
      width: this.width,
      height: this.height,
    };
    this.fullScreen = true;
    const element = this.elementRef.nativeElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element['webkitRequestFullscreen']) {
      element['webkitRequestFullscreen']();
    }
  }

  private exitFullScreen() {
    this.fullScreen = false;
    const element = this.elementRef.nativeElement;
    element.style.height = '100%';
    this.width = this.dimensionsBeforeFullscreen.width;
    this.height = this.dimensionsBeforeFullscreen.height;
    this.svgNode.setAttribute('width', `${this.width}`);
    this.svgNode.setAttribute('height', `${this.height}`);

    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    }
  }

  private resize(size: DOMRectReadOnly) {
    const padding = 25;
    const newWidth = size.width - padding * 2;
    const newHeight = size.height - padding * 2;

    if (newWidth === this.width && newHeight === this.height) return;
    this.width = newWidth;

    this.height = newHeight;
    if (this.type === 'sankey') {
      this.drawData();
      return;
    }

    if (!this.svgNode) this.drawData();

    const [, , w, h] = (
      this.svgNode?.getAttribute('viewBox') ?? '0 0 0 0'
    ).split(' ');
    const width = +w;
    const height = +h;

    const svgWidth = width < this.width ? width : this.width;
    let svgHeight = width < this.height ? height : this.height;
    if (this.fullWidth) {
      svgHeight = (height / width) * svgWidth;
    }
    this.svgNode.classList.add('mx-auto');
    this.svgNode.setAttribute('width', `${svgWidth}`);
    this.svgNode.setAttribute('height', `${svgHeight}`);
  }

  public restore() {
    this.drawData();
  }

  public download() {
    const serializer = new XMLSerializer();
    const copyNode = this.svgNode.cloneNode(true) as SVGElement;

    copyNode.removeAttribute('width');
    copyNode.removeAttribute('height');
    const svgBlob = new Blob([serializer.serializeToString(copyNode)], {
      type: 'image/svg+xml;charset=utf-8',
    });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(svgBlob);
    downloadLink.download = 'energy-diagram.svg';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  private drawData() {
    const width = this.width;
    const height = this.height;

    if (!this.dataset || !this.chart || !width || !height) return;
    if (height < 0) {
      console.warn('no height defined');
    }
    this.showWarning =
      this.dataset &&
      (this.dataset.nodes.length === 0 || this.dataset.links.lenght === 0);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.svgNode && this.chart.nativeElement.removeChild(this.svgNode);

    const dataset = {
      nodes: this.dataset.nodes
        .map((n) => ({ ...n, sortBy: EhubSortBy(n) }))
        .sort((n1, n2) => (n1.sortBy < n2.sortBy ? -1 : 1)),
      links: this.dataset.links,
    };

    if (this.type === 'ehub')
      this.svgNode = drawEhub(
        dataset,
        {
          useVirtualRoutes: true,

          nodeText: EhubNodeText,
          nodeColor: EhubNodeColor(!this.ignoreZeroCapcity),
          nodeTooltip: EhubNodeTooltip,
          linkTooltip: EhubLinkTooltip,
          linkArrow: EhubArrow(this.drawDoubleArrows),
          linkColor: EhubLinkColor(!this.ignoreZeroCapcity),
          settings: {
            width,
            height,
            minNodePadding: {
              y: EhubMinVerticalNodeY,
              x: EhubMinHorizontalNodeX,
            },
            nodeDimensions: EhubNodeDimension,
            nodeAllowedInFirstColumn: EhubFirstColumnAllowed,
            nodeAllowedInLastColumn: EhubLastColumnAllowed,
          },
        },
        this.tooltip,
      );
    if (this.type === 'sankey') {
      // return;
      this.svgNode = drawSankeyCirculair(
        this.dataset,
        {
          useVirtualRoutes: true,
          nodeText: (d: any) => d.edgeLabel ?? d.label ?? d.name,
          nodeColor: (d) => d.color,
          nodeTooltip: (d) => `
          ${d.edgeLabel}<br />
          `,
          linkTooltip: (link, source, target) => `${source.edgeLabel} -> ${
            target.edgeLabel
          }
          <br />
          ${formatNumberToPrintFormat(link.value)} ${link.unit?.unit}

          `,
          linkColor: (d: any) => d.color,
          settings: {
            width,
            height,
          },
        },
        this.tooltip,
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.svgNode && this.chart.nativeElement.appendChild(this.svgNode);
    this.showDiagram = true;
    this.zoom = new Zoom(this.svgNode);
  }

  @HostListener('wheel', ['$event'])
  public scrollWheel(event: WheelEvent) {
    event.preventDefault();

    this.zoom.zoom(event);
  }

  @HostListener('mousedown', ['$event'])
  public mouseDown() {
    this.zoom.startPan();
  }

  @HostListener('mouseup', ['$event'])
  public mouseup() {
    this.zoom.endPan();
  }

  @HostListener('mousemove', ['$event'])
  public mousemove(event: MouseEvent) {
    this.zoom.panMove(event);
  }
}
