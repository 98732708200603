import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { DB_TYPES } from '@sympheny/database/model';
import { ProjectVersion } from '@sympheny/project/data-access';
import { DialogFormComponent } from '@sympheny/ui/dialog';
import { InputComponent, TextareaComponent } from '@sympheny/ui/form';
import { UserState } from '@sympheny/user/data-access';

import { DatabaseSelectService } from '../service/database-select.service';

@Component({
  selector: 'sympheny-save-to-db-modal',
  templateUrl: './save-to-db-modal.component.html',
  styleUrls: ['./save-to-db-modal.component.scss'],
  imports: [
    TranslocoDirective,
    DialogFormComponent,
    ReactiveFormsModule,
    NgIf,
    InputComponent,
    TextareaComponent,
  ],
})
export class SaveToDbModalComponent<T> implements OnInit {
  @Input() public editMode: boolean;
  @Input() public target: DB_TYPES | 'ewz';
  @Input() public titleLabel: string;
  @Input() public data: T;
  @Input() public guid: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public readonly close = new EventEmitter<'edited' | 'added'>();

  public form: FormGroup;
  constructor(
    public readonly userState: UserState,
    private readonly controlContainer: ControlContainer,
    private readonly databasSelectService: DatabaseSelectService<any>,
  ) {}

  public ngOnInit() {
    this.form = this.controlContainer.control as FormGroup;
    this.databasSelectService.setDatabase(this.target);
  }

  public get db() {
    if (this.target === 'ewz') return 'ewz';
    if (this.target === 'database-org') return 'organization';

    return 'my';
  }

  public saveToDb() {
    const data = {
      ...this.data,
      ...this.form.value,
      fromOrg: this.target === 'database-org',
      comesFromDb:
        this.target === 'database-org' ? this.userState.organisationName : null,
    };

    if (this.editMode) {
      this.databasSelectService.update(this.guid, this.data).then(() => {
        this.close.emit('edited');
      });
    } else {
      this.databasSelectService.create(data).then(() => {
        this.close.emit('added');
      });
    }
  }

  @Input()
  public set projectVersion(projectVersion: ProjectVersion) {
    this.databasSelectService.setProjectVersion(projectVersion);
  }
}
