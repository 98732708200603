import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  IconButtonListConfiguration,
  IconButtonConfiguration,
  IconButtonListComponent,
} from '@sympheny/ui/button';
import { DialogService } from '@sympheny/ui/dialog';

@Component({
  selector: 'sympheny-crud-list-action',
  templateUrl: './crud-list-action.component.html',
  styleUrls: ['./crud-list-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconButtonListComponent],
})
export class CrudListActionComponent<ITEM> implements OnChanges {
  @Input() public subject: string;
  @Input() public disableEdit: boolean;
  @Input() public disabled: boolean;
  @Input() public readonly: boolean;
  @Input() public item: ITEM;

  @Output() public readonly editItem = new EventEmitter<ITEM>();
  @Output() public readonly deleteItem = new EventEmitter<ITEM>();

  public configuration: IconButtonListConfiguration = {
    buttons: [],
  };
  constructor(private readonly dialogService: DialogService) {}
  public ngOnChanges(): void {
    this.configuration = this.getButtons(this.item);
  }

  private getButtons(item: ITEM): IconButtonListConfiguration {
    if (this.readonly) {
      return this.createReadOnlyButtons(item);
    }

    return this.createEditButtons(item);
  }

  private createEditButtons(item: ITEM) {
    const buttons: IconButtonConfiguration[] = [
      {
        action: () => this.onDelete(item),
        icon: 'delete',
        color: 'warn',
        tooltip: '',
        disabled: this.disabled,
        dataCy: 'list-actions--delete',
      },
    ];

    if (!this.disableEdit) {
      buttons.push({
        action: () => this.editItem.emit(item),
        icon: 'edit',
        color: 'primary',
        tooltip: '',
        dataCy: 'list-actions--edit',
      });
    }

    return { buttons };
  }

  private onDelete(item: ITEM) {
    this.dialogService.openConfirmDelete(`${this.subject}`, () =>
      this.deleteItem.emit(item),
    );
  }

  private createReadOnlyButtons(item: ITEM) {
    const buttons: IconButtonConfiguration[] = [];

    if (!this.disableEdit) {
      buttons.push({
        action: () => this.editItem.emit(item),
        icon: 'visibility',
        color: 'primary',
        tooltip: '',
      });
    }

    return { buttons };
  }
}
