import { NgIf, DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'sympheny-scenario-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  imports: [NgIf, DatePipe, TranslocoDirective],
})
export class SummaryComponent {
  public readonly uuid = `summary-${uuidv4()}`;

  @Input() public notes: string | null = null;
  @Input() public showLastUpdated: boolean;
  @Input() public lastUpdated: null | Date | string | false = false;
  @Input() public source: null | string | false = false;

  @Input() public summaryTitle: string;
  @Input() public summarySubject: string;
  @Input() public summaryTitleArgs: Record<string, string>;

  public get sourceLink() {
    const [_, link] = this.source ? this.source.split(';') : [null, null];

    return link?.trim() ?? this.source;
  }
}
