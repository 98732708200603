import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';
import { RadioValue } from '../model/option-value.model';

@Component({
  selector: 'sympheny-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  imports: [
    TranslocoPipe,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    InfoButtonComponent,
    MatInputModule,
    NgIf,
    MatRadioModule,
    NgFor,
  ],
})
export class RadioGroupComponent<T> extends BaseFormComponent<
  any,
  FormFieldConfig
> {
  @Input() public labelKey: keyof T | 'label' = 'label';
  @Input() public options: Array<RadioValue<T>> = [];
  @Input() public direction: 'col' | 'row' = 'row';
  @Output() public readonly selectionChange = new EventEmitter<any | null>();

  public getLabel(option: any) {
    return option[this.labelKey];
  }

  public getValue(option: any) {
    return this.labelKey === 'label' ? option.value : option[this.labelKey];
  }

  public onSelectionChange(event: MatRadioChange) {
    const value = event.value;

    if (this.labelKey === 'label') {
      this.selectionChange.emit(value);
      return;
    }

    const findValue =
      this.options?.find((option) => this.getValue(option) === value) ?? null;

    this.selectionChange.emit(findValue as any);
  }
}
