import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoDirective } from '@ngneat/transloco';
import { LoaderComponent } from '@sympheny/ui/loader';
import { SnackbarService } from '@sympheny/ui/snackbar';

import { DialogActionsComponent } from '../dialog-actions/dialog-actions.component';

export interface DialogData {
  question?: string;
  title: string;
  isCopy?: boolean;
  submitLabel?: string;
  translateParams?: Record<string, any>;
  hideCancel?: boolean;
}
export interface ConfirmDialogData extends DialogData {
  subject: string;
  action: () => Promise<unknown> | void;
}
@Component({
  selector: 'sympheny-confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  imports: [DialogActionsComponent, TranslocoDirective, LoaderComponent],
})
export class ConfirmationDialogComponent {
  public loading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    protected readonly dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private readonly snackbarService: SnackbarService,
  ) {}

  public submitAction() {
    this.loading = true;
    if (!this.data.action) {
      this.dialogRef.close(true);
      return;
    }

    const action = this.data.action();

    if (!(action instanceof Promise)) {
      this.dialogRef.close(true);

      return;
    }

    action
      .then(() => {
        this.dialogRef.close(true);
        this.snackbarService.success(`${this.data.subject}.delete.success`);
      })
      .catch(() => {
        this.loading = false;
        this.snackbarService.error(`${this.data.subject}.delete.error`);
      });
  }
}
