import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';
import { addYears } from 'date-fns';

import { CustomDateAdapter } from './datepicker.adapter';
import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';
export interface MatInputConfig extends FormFieldConfig {
  minLength?: number;
  maxLength?: number;
}

@Component({
  selector: 'sympheny-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    TranslocoPipe,
    InfoButtonComponent,
    NgIf,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class DatePickerComponent extends BaseFormComponent<
  Date,
  MatInputConfig
> {
  @Input() public max = new Date(); // Today
  @Input() public min = addYears(this.max, -30);
  @Output() public readonly dateChange = new EventEmitter<void>();
}
