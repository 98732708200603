import { NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslocoPipe } from '@ngneat/transloco';
import { HubService } from '@sympheny/project/data-access';
import {
  ResponseStatusCode,
  ResponseStatusMessages,
  SymphenyErrorHandler,
} from '@sympheny/utils/data-access';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'sympheny-hub-diagram',
  templateUrl: './hub-diagram.component.html',
  styleUrls: ['./hub-diagram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, TranslocoPipe],
})
export class HubDiagramComponent implements OnChanges {
  showMessage = true;
  showImage = false;
  message = '';

  @Input() scenarioId: string;
  @Input() hubId: string;
  @Input() active: boolean;
  @Input() reloadHubDiagram: Date;

  trustedUrl: any;

  constructor(
    private hubService: HubService,
    private errorHandler: SymphenyErrorHandler,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.loadDiagram();
  }

  loadDiagram() {
    // Only load active diagram
    if (!this.active || !this.hubId || !this.scenarioId) {
      this.showMessage = true;
      this.message = '';
      this.showImage = false;
      this.cdr.detectChanges();
      return;
    }

    firstValueFrom(
      this.hubService.getHubsDiagram(this.scenarioId, [this.hubId]),
    )
      .then((result) => this.setImage(result?.diagrams ?? []))
      .catch((error: HttpErrorResponse) => {
        this.errorHandler.handleEspError(error, {
          code: ResponseStatusCode.ESP_4001_STATUS,
          message: ResponseStatusMessages.ERROR_GENERATE_HUBS_DIAGRAM,
        });
        return this.setImage([]);
      });
  }

  private setImage(diagrams: Array<{ image: string }>) {
    if (diagrams?.length < 1 || !diagrams[0].image) {
      this.message = 'HUBS.add.diagram.message';
      this.showMessage = true;
      this.showImage = false;

      this.cdr.detectChanges();
      return;
    }

    const image = diagrams[0].image;
    this.showMessage = false;
    this.message = '';
    this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/svg+xml;base64,${image}`,
    );

    this.showImage =
      this.trustedUrl.changingThisBreaksApplicationSecurity !== null &&
      this.trustedUrl.changingThisBreaksApplicationSecurity !== undefined &&
      this.trustedUrl.changingThisBreaksApplicationSecurity.length > 1;

    this.cdr.detectChanges();
  }
}
