import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Optional,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { ProjectState } from '@sympheny/project/data-access';
import { UserState } from '@sympheny/user/data-access';
import { Observable } from 'rxjs';

import { SidenavService } from './sidenav.service';
import { EnervisionService } from '../services/enervision.service';
import { SideNavExpansionItemComponent } from '../sidenav-expansion-item/sidenav-expansion-item.component';
import { SideNavItemComponent } from '../sidenav-item/side-nav-item.component';

@Component({
  selector: 'sympheny-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EnervisionService],
  imports: [
    MatIconModule,
    SideNavItemComponent,
    NgIf,
    SideNavExpansionItemComponent,
    NgFor,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class SidenavComponent {
  public readonly organizationDb$ =
    this.userState.getPlanLimitation('organizationDb');
  public readonly sagemakerOn$ =
    this.userState.getPlanLimitation('sagemakerOn');
  @HostBinding('class.sympheny--menu_open') public pushMode = false;
  public opened = false;
  @ViewChildren('accordion') private accordions!: QueryList<MatAccordion>;
  @ViewChildren(SideNavExpansionItemComponent)
  private sideNavItems!: QueryList<SideNavExpansionItemComponent>;
  public isDesignVisible$: Observable<boolean> =
    this.sidenavService.isDesignVisible$;
  public readonly projectDetails$ = this.projectState?.project$;
  public readonly analysisDetails$ = this.projectState?.activeAnalysis$;

  constructor(
    private readonly userState: UserState,
    private readonly sidenavService: SidenavService,
    public readonly router: Router,
    @Optional() private readonly projectState?: ProjectState,
    @Optional() private readonly enervisionService?: EnervisionService,
  ) {}

  public openSidenav(): void {
    this.opened = true;
  }

  public async openEnervisionWindow(): Promise<void> {
    const url = await this.enervisionService.getPresignedUrl();
    window.open(url);
  }

  public closeSidenav(): void {
    if (this.pushMode) return;
    this.closePanels();
    this.opened = false;
  }

  public changeSidenavMode(): void {
    this.pushMode = !this.pushMode;
    if (this.pushMode) {
      this.openSidenav();
    } else {
      this.closeSidenav();
    }
    this.sidenavService.isOpen$.next(this.pushMode);
  }

  private closePanels() {
    this.accordions.forEach((accordion) => {
      accordion.closeAll();
    });
    this.sideNavItems.forEach((sideNavItem) => {
      sideNavItem.closeAll();
    });
  }
}
