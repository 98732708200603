import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { LayerType } from '@sympheny/project/scenario/data-access';
import { MoreButtonConfiguration, MoreMenuItem } from '@sympheny/ui/button';
import { ButtonComponent } from '@sympheny/ui/button';
import { MoreButtonComponent } from '@sympheny/ui/button';
import { ColorPickerComponent } from '@sympheny/ui/color-picker';
import { DialogService } from '@sympheny/ui/dialog';
import { InputComponent } from '@sympheny/ui/form';
import { SelectComponent } from '@sympheny/ui/form';
import { FeatureCollection } from 'geojson';

import { MapLayer } from '../../layer/map-layer';
import { AddCustomerLayerComponent } from '../add-customer-layer/add-customer-layer.component';

export interface Data {
  layer_name: string;
  layer_color: string;
  feature_collection: FeatureCollection;
}

export interface LayerConfigurationEvent {
  layerId: string;
  layerType: LayerType;
  data: Data;
}

@Component({
  selector: 'sympheny-layer-configuration',
  templateUrl: './layer-configuration.component.html',
  styleUrls: ['./layer-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    SelectComponent,
    NgIf,
    MoreButtonComponent,
    InputComponent,
    ColorPickerComponent,
    ButtonComponent,
    TranslocoPipe,
  ],
})
export class LayerConfigurationComponent {
  @Input() public disabled = false;
  @Input() public canEdit = false;
  @Input() public editableLayers: MapLayer[] = [];
  @Output() public readonly delete = new EventEmitter<{
    layerId: string;
    layerType: LayerType;
  }>();
  @Output() readonly save = new EventEmitter<LayerConfigurationEvent>();

  selectedLayer: MapLayer | null = null;

  private addMenuItem: MoreMenuItem = {
    tooltip: 'map.layer.add',
    label: 'map.layer.add',
    action: () => this.onAdd(),
  };

  private readonly deleteMenuItem: MoreMenuItem = {
    tooltip: 'map.layer.delete',
    label: 'map.layer.delete',
    action: () => this.onDelete(),
  };

  readonly moreConfiguration: MoreButtonConfiguration = {
    items: [this.addMenuItem, this.deleteMenuItem],
    tooltip: '',
  };

  readonly editLayersForm = new FormGroup({
    name: new FormControl<string>(
      { value: '', disabled: true },
      { nonNullable: true },
    ),
    color: new FormControl<string | null>({ value: '', disabled: true }),
    selectedLayerId: new FormControl<string | null>(null),
  });

  constructor(
    private readonly dialogService: DialogService,
    private readonly viewContainerRef: ViewContainerRef,
  ) {}

  private resetSelection() {
    this.editLayersForm.reset();
    this.editLayersForm.disable();

    this.selectedLayer = null;
  }

  setSelectedLayer(newValue: string | null) {
    if (!newValue) {
      this.resetSelection();
      return;
    }

    this.selectedLayer =
      this.editableLayers.find((l) => l.layerId === newValue) ?? null;
    if (!this.selectedLayer) {
      this.resetSelection();
      return;
    }

    this.editLayersForm.enable();

    this.editLayersForm.setValue({
      name: this.selectedLayer.title,
      color: this.selectedLayer.color,
      selectedLayerId: this.selectedLayer.layerId,
    });
  }

  changeColor() {
    const { color } = this.editLayersForm.value;
    if (!this.selectedLayer || !color) {
      return;
    }

    this.selectedLayer.changeColor(color);
  }

  onSave() {
    if (this.editLayersForm.invalid) {
      return;
    }

    const { name, selectedLayerId, color } = this.editLayersForm.value;

    if (!selectedLayerId || !name || !this.selectedLayer) {
      return;
    }

    this.save.emit({
      layerId: selectedLayerId,
      layerType: this.selectedLayer.layerType,
      data: {
        layer_color: color!,
        layer_name: name!,
        feature_collection: this.selectedLayer.geoJson,
      },
    });
  }

  onAdd() {
    this.dialogService.openDialog(
      AddCustomerLayerComponent,
      {},
      null,
      this.viewContainerRef,
    );
  }

  onDelete() {
    if (!this.selectedLayer) {
      return;
    }
    const { layerId, layerType, title } = this.selectedLayer;

    const confirmed = () => {
      this.delete.emit({
        layerId,
        layerType,
      });
      this.editLayersForm.reset();
    };

    this.dialogService.openConfirmationDialog(
      {
        title: `Deleting ${title}, are you sure?`,
        question:
          'Deleting a layer is a permanent operation and can’t be undone.',
      },
      confirmed,
    );
  }
}
