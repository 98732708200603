import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MarkdownComponent as MdComponent } from 'ngx-markdown';
import { map } from 'rxjs';

@Component({
  selector: 'sympheny-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss'],
  imports: [MdComponent, AsyncPipe],
})
export class MarkdownComponent {
  public readonly src$ = this.route.params.pipe(
    map((param) => `/assets/api-docs/${param['page']}.md`),
  );

  constructor(private readonly route: ActivatedRoute) {}
}
