import { NgFor, NgIf } from '@angular/common';
import {
  Output,
  Component,
  Input,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOption, MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MatSelect,
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';
import { OptionValue } from '../model/option-value.model';

@Component({
  selector: 'sympheny-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  imports: [
    MatFormFieldModule,
    NgIf,
    MatSelectModule,
    MatCheckboxModule,
    InfoButtonComponent,
    MatOptionModule,
    ReactiveFormsModule,
    FormsModule,
    TranslocoPipe,
    NgFor,
  ],
})
export class SelectComponent<T> extends BaseFormComponent<
  any,
  FormFieldConfig
> {
  @Input() public translateOptions = true;
  @Input() public labelKey: keyof T | 'label' = 'label';
  @Input() public valueKey: keyof T | 'value' = 'value';
  @Input() public options:
    | Array<OptionValue<T> | T>
    | Readonly<Array<OptionValue<T> | T>> = [];
  @Input() public disableSelectAll = false;
  @Input() public multiple = false;
  @Input() public maxValues: number;
  @Input() public disabledOptions: Array<T | string> = [];

  @Output() public readonly selectionChange = new EventEmitter<any | null>();

  @ViewChild(MatSelect) public readonly select!: MatSelect;
  public allSelected = false;

  private previousSelection: T[] = [];

  public trackByValue() {
    return (_, option: any) => {
      return this.getValue(option);
    };
  }

  public override writeValue(value: any): void {
    this.previousSelection = value;
    super.writeValue(value);
  }
  public getLabel(option: any) {
    return option[this.labelKey ?? 'label'];
  }

  public getValue(option: any) {
    return option[this.valueKey ?? 'value'];
  }

  public onSelectionChange(event: MatSelectChange) {
    let value = event.value;

    if (this.multiple) {
      if (this.maxValues === 1 && value.length > 1) {
        value = value.filter((v) => !this.previousSelection.includes(v));
        this.formControl.patchValue(value);
      }

      this.previousSelection = value;

      const findValues = value.map((v) => this.findValue(v));
      this.selectionChange.emit(findValues as any);
      return;
    }

    const findValue = this.findValue(value);
    this.selectionChange.emit(findValue as any);
  }

  public toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) =>
        item.disabled ? null : item.select(),
      );
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  /**
   * Hack to overwrite the SELECT_PANEL_MAX_HEIGHT=256 on a nice way
   * @param open
   * @returns
   */
  public onOpenChange(open: boolean) {
    if (!open) {
      return;
    }

    // const top = this.select._triggerRect.y + 12;
    // const totalHeight = this.select.panel.nativeElement.offsetHeight + top;

    // if (clientHeight - totalHeight < 0) {
    //   this.select.panel.nativeElement.style.maxHeight = `${
    //     clientHeight - top
    //   }px`;
    // }
  }

  public optionDisabled(option: any) {
    if (!this.disabledOptions) {
      return false;
    }

    const value = this.getValue(option);
    return this.disabledOptions.includes(value);
  }

  private findValue(value: any) {
    return (
      this.options?.find((option) => this.getValue(option) === value) ?? null
    );
  }
}
