import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';
import { NgxMaskDirective } from 'ngx-mask';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';

export type MatInputConfig = FormFieldConfig;

@Component({
  selector: 'sympheny-year-number',
  templateUrl: './input-year.component.html',
  styleUrls: ['./input-year.component.scss'],
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    TranslocoPipe,
    InfoButtonComponent,
    NgxMaskDirective,
    NgIf,
  ],
})
export class InputYearComponent extends BaseFormComponent<
  number,
  MatInputConfig
> {
  public value: number | null = null;
  mask = '9999';

  constructor(ngControl: NgControl) {
    super(ngControl);
  }
}
