import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
  selector: 'sympheny-sidenav-item',
  templateUrl: './side-nav-item.component.html',
  styleUrls: ['./side-nav-item.component.scss'],
  imports: [
    NgIf,
    RouterLinkActive,
    RouterLink,
    NgTemplateOutlet,
    MatIconModule,
  ],
})
export class SideNavItemComponent {
  @Input() public link!: string | any[];
  @Input() public params!: any;
  @Input() public href: string | undefined;
  @Input() public icon: string | undefined;
  @Input() public opened = false;
}
