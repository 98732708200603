import { CdkPortalOutlet, PortalModule } from '@angular/cdk/portal';
import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { FormatValueComponent } from '@sympheny/format-value';

import { DialogSummaryValueConfig } from '../model';

@Component({
  selector: 'sympheny-dialog-summary-value',
  templateUrl: './dialog-summary-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PortalModule,
    TranslocoDirective,
    NgIf,
    NgClass,
    FormatValueComponent,
  ],
})
export class DialogSummaryValueComponent<T> implements OnChanges {
  @Input() public summary!: DialogSummaryValueConfig<T>;
  @Input() public element!: T;
  @Input() public rowIndex: number;
  @ViewChild(CdkPortalOutlet, { static: true })
  public portalOutlet!: CdkPortalOutlet;

  public value: any;
  public cssClass = '';

  public type: string;
  public tooltip = '';
  public showSuffix = false;

  public ngOnChanges() {
    if (!this.summary || !this.element || this.summary.type === 'BLANK') {
      this.value = null;
      this.type = 'blank';
      this.showSuffix = false;
      return;
    }

    this.type = this.summary.type;
    this.showSuffix =
      this.type !== 'component' &&
      this.type !== 'template' &&
      !!this.summary.suffix &&
      this.element[this.summary.key] !== null;

    this.cssClass = this.summary.cssClassFn?.(this.element) ?? '';
  }
}
