import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sympheny-loader',
  templateUrl: './loader.component.html',
  host: { class: 'relative justify-center flex' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf],
})
export class LoaderComponent {
  @Input() public loading: boolean;
}
