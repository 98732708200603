import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import 'mermaid';
// import 'clipboard';
import 'prismjs';
import 'prismjs/components/prism-powershell.min.js';
import 'prismjs/components/prism-typescript.min.js';
import 'prismjs/components/prism-json.min.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
import 'prismjs/plugins/line-highlight/prism-line-highlight.js';
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.js';

import { MarkdownModule } from 'ngx-markdown';

import { MarkdownComponent } from './markdown/markdown.component';

const routes: Routes = [
  {
    path: 'documentation/:page',
    component: MarkdownComponent,
    data: {
      breadcrumb: 'Documentation',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    MarkdownModule.forRoot({ loader: HttpClient }),
    MarkdownComponent,
  ],
})
export class ApiDocsModule {}
