import { NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';

import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'sympheny-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  imports: [NgIf, NgFor, RouterLink, TranslocoDirective],
})
export class BreadcrumbComponent {
  public readonly labels$ = this.breadcrumbService.labels;

  constructor(private readonly breadcrumbService: BreadcrumbService) {}
}
