import { NgIf } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatExpansionModule,
  MatExpansionPanel,
} from '@angular/material/expansion';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';

import {
  FormSectionField,
  FormSectionContentComponent,
} from '../form-section-content/form-section-content.component';

@Component({
  selector: 'sympheny-scenario-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss'],
  imports: [
    MatExpansionModule,
    InfoButtonComponent,
    TranslocoPipe,
    NgIf,
    ReactiveFormsModule,
    FormSectionContentComponent,
  ],
})
export class FormSectionComponent<T> implements OnInit {
  @Input() public disabled: boolean;
  @Input() public expanded: boolean;
  @Input() public sectionTitle: string;
  @Input() public titleInfo: string;
  @Input() public description: string;

  @Input() public fields: FormSectionField<T>[];
  @Input() public elementsRow: 1 | 3 | 4 = 3;

  @ViewChild(MatExpansionPanel) public readonly panel: MatExpansionPanel;

  public parentForm: FormGroup;

  public open() {
    this.panel.open();
  }

  constructor(private readonly controlContainer: ControlContainer) {}

  public ngOnInit() {
    this.parentForm = this.controlContainer.control as FormGroup;
  }
}
