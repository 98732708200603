import { NgIf, NgFor } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent, ButtonComponent } from '@sympheny/ui/button';
import { LoaderComponent } from '@sympheny/ui/loader';
import {
  TableConfiguration,
  TableColumn,
  TableDefinedAction,
  TableAction,
  TableDefinedActionDisabled,
  TableComponent,
} from '@sympheny/ui/table';

export interface CrudTableAction {
  label: string;
  dataCy?: string;
  action: () => void;
}
@Component({
  selector: 'sympheny-crud-table-detail',
  templateUrl: './crud-table-detail.component.html',
  styleUrls: ['./crud-table-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    InfoButtonComponent,
    NgFor,
    ButtonComponent,
    LoaderComponent,
    TableComponent,
    TranslocoPipe,
  ],
})
export class CrudTableDetailComponent<ITEM> implements OnChanges {
  @Input() public items: ITEM[];
  @Input() public tooltip?: string;
  @Input() public addLabel = 'general.addNew';
  @Input() public loading = false;
  @Input() public labelParams: Record<string, any> = {};

  @Input() public readonly: boolean;
  @Input() public canEdit: boolean;
  @Input() public disabled: boolean;

  @Input() public columns: TableColumn<ITEM>[];
  @Input() public definedActions?: TableDefinedAction<ITEM>;
  @Input() public customActions?: TableAction<ITEM>[];

  @Input() public actions: CrudTableAction[] = [];
  @Input() public subject: string;

  public configuration: TableConfiguration<ITEM> = {
    columns: [],
    customActions: [],
  };

  public disableDefinedActions: TableDefinedActionDisabled = {};

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['columns'] ||
      changes['canEdit'] ||
      changes['readonly'] ||
      changes['disabled'] ||
      changes['definedActions']
    ) {
      this.configuration = {
        columns: this.columns,
        customActions: this.customActions ?? [],
        definedActions: {
          label: this.definedActions?.label,
          viewDetails: this.definedActions?.viewDetails,
          download: this.definedActions?.download,
          edit:
            this.readonly || this.disabled ? null : this.definedActions?.edit,
          view:
            this.definedActions?.view ?? (this.readonly || this.disabled)
              ? this.definedActions?.edit
              : null,
          delete: this.readonly ? null : this.definedActions?.delete,
        },
      };
    }

    if (changes['disabled']) {
      this.disableDefinedActions = {
        delete: this.disabled,
      };
    }
  }
}
