import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';

import { FormatValueComponent } from '../format-value/format-value.component';
import { FormatValueConfig } from '../model/format-value';

@Component({
  selector: 'sympheny-label-value',
  templateUrl: './label-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoDirective, FormatValueComponent, NgIf],
})
export class LabelValueComponent<T> {
  @Input() public format!: FormatValueConfig<T>;
  @Input() public element!: T;
  @Input() public currency: string;
}
