import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoDirective } from '@ngneat/transloco';

import { FormFieldConfig } from '../base-form/base-form.component';

export type MatInputConfig = FormFieldConfig;

const suffixValues = {
  percent: '%',
  kw: 'kW',
  kwh: 'kWh',
  kwhYear: 'kWh/year',
  m: 'm',
  kwYear: 'kW/year',
  year: 'year',
} as const;

export type Appereance = keyof typeof suffixValues;

@Component({
  selector: 'sympheny-input-suffix',
  templateUrl: './input-suffix.component.html',
  imports: [TranslocoDirective, NgIf, MatTooltipModule],
})
export class InputSuffixComponent {
  @Input() public suffix: Appereance | null;
  @Input() public suffixLabel: string | null;
  @Input() public suffixParams: Record<string, any>;

  public get suffixValue() {
    return this.suffix ? suffixValues[this.suffix] : null;
  }
}
