import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslocoPipe } from '@ngneat/transloco';
import { Hub } from '@sympheny/project/data-access';
import { ScenarioStore } from '@sympheny/project/scenario/data-access';
import { sortByDateFn } from '@sympheny/utils/sort';
import { Subject } from 'rxjs';

import { HubDiagramComponent } from './hub-diagram/hub-diagram.component';

@Component({
  selector: 'sympheny-hubs-diagram',
  templateUrl: './hubs-diagram.component.html',
  styleUrls: ['./hubs-diagram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    MatTabsModule,
    NgFor,
    HubDiagramComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class HubsDiagramComponent implements OnChanges, OnDestroy {
  // Inputs
  @Input() public scenarioId!: string;
  @Input() public hubs: Hub[] = [];
  public hubsSorted: Hub[];
  @Output() public readonly selectedHubGuidEmitter = new EventEmitter<string>();

  public selectedHubGuid$ = this.scenarioStore.selectValue('selectedHubGuid');
  public reloadHubDiagram$ = this.scenarioStore.selectValue('reloadHubDiagram');

  public selectedIndex = 0;

  private readonly destroy$ = new Subject<void>();

  constructor(private scenarioStore: ScenarioStore) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['hubs']) {
      this.setHubs();
    }
  }

  public selectHub(index: number) {
    const hub = this.hubsSorted[index];
    this.scenarioStore.selectHubGuid(hub.hubGuid);
    this.selectedHubGuidEmitter.emit(hub.hubGuid);
    this.selectedIndex = index;
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private setHubs() {
    this.hubsSorted = sortByDateFn(this.hubs, 'created', 'desc');
    const selectedHubGuid = this.scenarioStore.getValue('selectedHubGuid');
    const findSelectedHub =
      selectedHubGuid &&
      this.hubsSorted.some((h) => h.hubGuid === selectedHubGuid);
    if (this.hubs?.length && !findSelectedHub) {
      this.selectHub(0);
    }
  }
}
