import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';
import { BaseFormComponent } from '@sympheny/ui/form';

@Component({
  selector: 'sympheny-color-picker-extended',
  templateUrl: './color-picker-extended.component.html',
  styleUrls: ['./color-picker-extended.component.scss'],
  imports: [
    TranslocoPipe,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    InfoButtonComponent,
    MatInputModule,
    NgIf,
    MatMenuModule,
    MatButtonModule,
  ],
})
export class ColorPickerExtendedComponent extends BaseFormComponent<
  string,
  any
> {}
