import {
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  DecimalPipe,
  DatePipe,
} from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getYear, isDate } from 'date-fns';

@Component({
  selector: 'sympheny-feature-info-value',
  templateUrl: './feature-info-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgSwitch, NgSwitchCase, NgSwitchDefault, DecimalPipe, DatePipe],
})
export class FeatureInfoValueComponent {
  private _value: any;
  public type: string;

  @Input() public set value(value: any) {
    this._value = this.formatValue(value);
  }

  public get value() {
    return this._value;
  }

  private formatValue(value: any) {
    if (value === undefined || value === null) {
      return value;
    }

    if (typeof value === 'number' && value > getYear(new Date())) {
      // Year should not be formated, so a quick fix is check if the number is bigger than the current year
      this.type = 'number';
      return value; //formatNumber(value, 'en-us', '1.2-2');
    }

    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    if (isDate(value)) {
      this.type = 'datetime';
      return value;
    }

    if (typeof value === 'string' && value.startsWith('http')) {
      this.type = 'link';
      return value;
    }

    return value;
  }
}
