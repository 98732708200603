import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoPipe } from '@ngneat/transloco';
import { IconComponent } from '@sympheny/ui/icon';

import { ButtonSize } from '../button-size';

@Component({
  selector: 'sympheny-icon-fab-button',
  templateUrl: './icon-fab-button.component.html',
  styleUrls: ['./icon-fab-button.component.scss'],
  imports: [IconComponent, TranslocoPipe, MatTooltipModule],
})
export class IconFabButtonComponent {
  @Input() public disabled = false;
  @Input() public tooltip = '';
  @Input() public size: ButtonSize = 'regular';
  @Input() public icon = '';

  @Input() public fontSet: string;
  @Input() public dataCy: string;

  @Output() public readonly action = new EventEmitter<void>();
}
