import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { defaultErrorMessages } from '../default-error.messages';
import { Appereance } from '../input-suffix/input-suffix.component';
import { SymphenyFormControl } from '../sympheny-form.control';

export interface FormFieldConfig {
  requiredHint?: boolean;
  fullWidth?: boolean;
  initialFocus?: boolean;
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    return !!(control && control.invalid && control.touched);
  }
}

@Component({
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss'],
  standalone: false,
})
export abstract class BaseFormComponent<T, CONFIG extends FormFieldConfig>
  implements ControlValueAccessor, AfterViewInit, OnInit, AfterContentChecked
{
  @Input() public translate: boolean;
  @Input() public placeholder!: string;
  @Input() public hideLabel: boolean;
  @Input() public label?: string;
  @Input() public labelParams?: Record<string, any>;
  @Input() public dataCy?: string;
  @Input() public ariaLabel?: string;
  @Input() public errorMessages: Record<string, string> = {};
  @Input() public hint?: string;
  @Input() public hintLink?: string;
  @Input() public appearance: 'fill' | null = null;

  @Input() public config: CONFIG | null = null;
  @Input() public required = false;
  @Input() public requiredMessage = 'FORM.requiredHint';
  @Input() public suffix: Appereance | null;
  @Input() public suffixLabel: string | null;

  @ViewChild('inputElement') public inputElement!: ElementRef;

  public formControl = new FormControl<any>(null);
  public onChange!: (value: any) => void;
  public isDisabled = false;
  public readonly matcher = new MyErrorStateMatcher();

  constructor(protected ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  public get requiredHint() {
    return (
      this.config?.requiredHint ||
      this.required ||
      this.formControl?.hasValidator(Validators.required)
    );
  }

  public get invalid() {
    return (
      this.formControl &&
      this.formControl.invalid &&
      this.formControl.touched &&
      this.formControl.dirty
    );
  }

  @Input()
  public set disabled(disabled: boolean) {
    this.isDisabled = disabled;
  }

  public ngOnInit() {
    this.formControl = this.ngControl.control as FormControl;
    if (!this.formControl) {
      console.warn('formcontrol not found', this.ngControl);
    }
  }

  public ngAfterViewInit(): void {
    // syncing with validators on host element
    this.formControl =
      this.formControl || (this.ngControl.control as FormControl);

    if (!this.formControl) {
      console.warn(`FormControl ${this.label ?? this.placeholder} not found`);
    }

    if (this.inputElement && this.config?.initialFocus) {
      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      });
    }
  }

  private init = false;

  public ngAfterContentChecked() {
    if (this.formControl instanceof SymphenyFormControl && !this.init) {
      const { format } = this.formControl;
      this.init = true;

      if (!format) {
        console.log(this);
        console.error('no format avaialble for ', this.formControl);
        return;
      }

      this.label = format.label;
      this.labelParams = format.params;
      this.suffixLabel = format.suffix;
      this.suffix = null;
      this.hint = format.hint ?? this.hint;
      this.hintLink = format.hintLink ?? this.hintLink;
    }
  }

  public writeValue(value: T | null | undefined): void {
    // this.formControl.patchValue() = value;
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public registerOnTouched(fn: T): void {}

  public setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  public getErrorMessage() {
    const error = Object.keys(this.formControl.errors ?? []);
    const errorKey = error[0];
    return errorKey
      ? this.errorMessages[errorKey] ??
          defaultErrorMessages[errorKey] ??
          `VALIDATION.${errorKey}`
      : '';
  }
}
