import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, Input, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { DB_TYPES } from '@sympheny/database/model';
import { ProjectVersion } from '@sympheny/project/data-access';
import { SelectListComponent } from '@sympheny/ui/form';
import { map, Subject, takeUntil } from 'rxjs';

import { SelectDbInitalValue } from './model';
import { DatabaseSelectService } from '../service/database-select.service';

@Component({
  selector: 'sympheny-db-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.scss'],
  imports: [ReactiveFormsModule, SelectListComponent, AsyncPipe, TranslocoPipe],
})
export class DbSelectItemComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject<void>();
  @Input() public categoryHeaderLabel = 'DATABASE.technologyCategory';
  @Input() public categoryInfoLabel = 'DATABASE.category.info';
  @Input() public categoryValue = 'value';
  @Input() public categoryLabel = 'label';
  @Input() public technologyHeaderLabel = 'DATABASE.technology';
  @Input() public technologyInfoLabel = 'DATABASE.technology.info';
  @Input() public technologyLabel = 'label';
  @Input() public technologyValue = 'value';
  @Input() public multi: boolean;

  @Output() public readonly selectDb = this.databasSelectService.database$;
  @Output() public readonly selectCategory =
    this.databasSelectService.category$;
  @Output() public readonly selectValue =
    this.databasSelectService.selectedValue$.pipe(
      map((value) => (value ? (this.multi ? value : value[0]) : null)),
    );
  public readonly form = new FormGroup({
    database: new FormControl<DB_TYPES | 'ewz'>(null, Validators.required),
    category: new FormControl<string>(null, Validators.required),
    technology: new FormControl<string | string[]>(null, Validators.required),
  });
  public readonly databases$ = this.databasSelectService.databases$;
  public readonly categories$ = this.databasSelectService.categories$;
  public readonly technologies$ = this.databasSelectService.technologies$;
  public readonly selectedValue$ = this.databasSelectService.selectedValue$;

  @Input() public set showEwz(showEwz: boolean) {
    this.databasSelectService.setEwz(showEwz);
  }
  @Input() public set projectVersion(projectVersion: ProjectVersion) {
    this.databasSelectService.setProjectVersion(projectVersion);
  }

  @Input() public set showUser(showUser: boolean) {
    this.databasSelectService.showUser(showUser);
  }

  @Input() public set showSymphenyDb(showSymphenyDb: boolean) {
    this.databasSelectService.showSymphenyDb(showSymphenyDb);
  }
  @Input() public set showOrganization(showOrganization: boolean) {
    this.databasSelectService.showOrganization(showOrganization);
  }

  @Input() public set initialValue(initialValue: SelectDbInitalValue) {
    if (!initialValue) {
      return;
    }
    const value = {
      database: initialValue.database,
      category: initialValue.category,
      technology: initialValue.technology ?? [],
    };
    this.form.patchValue(value);
    this.databasSelectService.initalValue(value);
  }

  constructor(
    private readonly databasSelectService: DatabaseSelectService<any>,
  ) {
    // TO avoid breaking of the services
    this.databasSelectService.setProjectVersion('V1');
  }

  public ngOnInit(): void {
    if (this.multi) {
      this.technologies$.pipe(takeUntil(this.destroy$)).subscribe((techs) => {
        this.form.patchValue(
          {
            technology: techs?.map((tech) => tech.guid),
          },
          { emitEvent: false },
        );
        this.changeTechnology();
      });
    }
  }

  public changeDB() {
    this.form.patchValue({ technology: null, category: null });
    this.databasSelectService.setDatabase(this.form.value.database);
  }

  public changeCategory() {
    this.form.patchValue({ technology: null });
    this.databasSelectService.setCategory(this.form.value.category);
  }

  public changeTechnology() {
    const { technology } = this.form.value;

    this.databasSelectService.setTechnology(
      typeof technology === 'string' ? [technology] : technology,
    );
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
