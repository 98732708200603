import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Dataset } from '@sympheny/gis/datasets';
import { MoreButtonConfiguration, MoreMenuItem } from '@sympheny/ui/button';
import { MoreButtonComponent } from '@sympheny/ui/button';
import { DialogService } from '@sympheny/ui/dialog';
import { SelectComponent } from '@sympheny/ui/form';

import { UploadGisDataComponent } from '../upload-gis-data/upload-gis-data.component';

export const GLOBAL_DATASET = 'SYMPHENY_GLOBAL_DS_ID' as const;
export type globalType = typeof GLOBAL_DATASET;

@Component({
  selector: 'sympheny-select-dataset',
  templateUrl: './select-dataset.component.html',
  styleUrls: ['./select-dataset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SelectComponent, ReactiveFormsModule, NgIf, MoreButtonComponent],
})
export class SelectDatasetComponent {
  @Input() public disabled?: boolean;
  @Input() public canEdit?: boolean;
  @Output() public readonly changeDataset = new EventEmitter<Dataset | null>();
  @Output() public readonly delete = new EventEmitter<Dataset>();

  public allDatasets: Dataset[] = [
    {
      dataset_id: GLOBAL_DATASET,
      dataset_name: 'Sympheny Global Dataset',
      addresses: [],
    },
  ];

  private addMenuItem: MoreMenuItem = {
    tooltip: 'Add dataset',
    label: 'Add dataset',
    action: () => this.onAddDataset(),
  };

  private readonly deleteMenuItem: MoreMenuItem = {
    tooltip:
      'Delete the selected dataset, this will delete the dataset for other users',
    label: 'Delete dataset',
    action: () => this.onDeleteDataset(),
  };

  private readonly sepMenuItems: MoreMenuItem[] = [this.addMenuItem];
  private readonly menuItems: MoreMenuItem[] = [
    this.addMenuItem,
    this.deleteMenuItem,
  ];

  public moreConfiguration: MoreButtonConfiguration = {
    items: this.sepMenuItems,
    tooltip: '',
  };

  constructor(private dialogService: DialogService) {}

  public dataset = new FormControl<Dataset | globalType>(GLOBAL_DATASET);

  @Input() public set selectedDataset(dataset: Dataset) {
    this.dataset.setValue(dataset ?? GLOBAL_DATASET);
  }

  public onSelectDataset(dataset: Dataset | globalType) {
    const isSep = dataset === GLOBAL_DATASET;
    this.changeDataset.emit(isSep ? null : dataset);

    const items = isSep ? this.sepMenuItems : this.menuItems;
    this.moreConfiguration = { items, tooltip: '' };
  }

  @Input() public set datasets(datasets: Dataset[]) {
    this.allDatasets = [
      {
        dataset_id: GLOBAL_DATASET,
        dataset_name: 'Sympheny Global Dataset',
        addresses: [],
      },
      ...datasets,
    ];
  }

  public onDeleteDataset() {
    const confirmed = () => {
      this.delete.emit(this.dataset.value as Dataset);
    };
    this.dialogService.openConfirmationDialog(
      {
        title: 'Are you sure?',
        question: `Deleting a dataset is a permanent operation and can’t be undone.
        You will loose all dataset information for every project.`,
      },
      confirmed,
    );
  }

  public onAddDataset() {
    this.dialogService.openDialog(UploadGisDataComponent, {
      dataset: this.dataset.value,
    });
  }

  public onEditDataset() {
    this.dialogService.openDialog(UploadGisDataComponent, {
      dataset: this.dataset.value,
    });
  }
}
