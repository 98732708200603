import { NgIf, DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { DatasetAddress, DatasetField } from '@sympheny/gis/datasets';
import { Address } from '@sympheny/project/scenario/data-access';

import { KeyLabel, KeysLabel } from '../model/building-information';

@Component({
  selector: 'sympheny-building-information-field',
  templateUrl: './building-information-field.component.html',
  styleUrls: ['./building-information-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, DecimalPipe, TranslocoPipe],
})
export class BuildingInformationFieldComponent implements OnChanges {
  @Input() public customField: string | null = null;
  @Input() public keyLabel: KeyLabel | KeysLabel | null = null;
  @Input() public address: Address;
  @Input() public datasetAddress: DatasetAddress | null;
  @Input() public displayDataset: boolean;

  public displayAsLink = false;
  public displayAsNumber = false;
  public showValue = false;
  public value: number | string | null;

  public ngOnChanges(): void {
    if (this.keyLabel) {
      if ('keys' in this.keyLabel) {
        const keysLabel = this.keyLabel as KeysLabel;
        const values = keysLabel.keys.map((key) => this.getValue(key));
        const hasEmpty = values.some((v) => !v);
        this.value = hasEmpty ? null : keysLabel.calculate(values);
        this.displayAsNumber = true;
      } else {
        this.value = this.getValue(this.keyLabel.key);
        this.displayAsNumber = !!this.keyLabel.number;
        this.displayAsLink = !!this.keyLabel.link;
      }
    }
    if (this.customField) {
      this.value = this.getCustomField(this.customField!);
    }

    this.showValue = !!this.value;
  }

  private getCustomField(key: string) {
    if (this.displayDataset) {
      return (
        this.datasetAddress?.fields.find(
          (f: DatasetField) => f.custom_name === key,
        )?.value ?? null
      );
    }
    return null;
  }

  private getValue(key: keyof Address) {
    if (this.displayDataset) {
      return (
        this.datasetAddress?.fields.find(
          (f: DatasetField) => f.default_name === key,
        )?.value ?? null
      );
    }
    return this.address[key];
  }
}
