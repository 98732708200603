import { NgFor, NgIf, KeyValuePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Optional,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslocoPipe } from '@ngneat/transloco';
import { gisStyles } from '@sympheny/gis/utils';
import { Hub } from '@sympheny/project/data-access';
import {
  ScenarioEditDialogService,
  ScenarioHubEditDialog,
} from '@sympheny/project/scenario/edit';
import { IconButtonComponent } from '@sympheny/ui/button';
import { Feature } from 'ol';
import { Layer } from 'ol/layer';

import { FeatureInfoValueComponent } from './feature-info-value/feature-info-value.component';

export interface FeatureLayerInfo {
  feature: Feature;
  layer: Layer;
}
@Component({
  selector: 'sympheny-feature-info',
  templateUrl: './feature-info.component.html',
  styleUrls: ['./feature-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCardModule,
    MatTabsModule,
    NgFor,
    MatTableModule,
    FeatureInfoValueComponent,
    NgIf,
    IconButtonComponent,
    KeyValuePipe,
    TranslocoPipe,
  ],
})
export class FeatureInfoComponent implements OnChanges {
  @Input() public mapUuid: string;
  @Input() public scenarioId: string;
  @Input() public hubs: Hub[];
  @Input() public featureData: FeatureLayerInfo[];
  @Output() public readonly openBuildingInfo = new EventEmitter<void>();

  public translatePrefix: string;

  private selectedFeature: Feature;
  constructor(
    @Optional()
    @Inject(ScenarioHubEditDialog)
    private readonly hubsService: ScenarioEditDialogService,
    private readonly viewContainerRef: ViewContainerRef,
  ) {}
  public get editHub() {
    return !!this.hubsService;
  }

  public ngOnChanges() {
    if (this.featureData) {
      this.changeSelectedFeature(0);
    }
  }

  public changeSelectedFeature(index: number) {
    this.selectedFeature?.setStyle(gisStyles.savedFeatures);
    const feature = this.featureData[index] ?? null;
    this.selectedFeature = feature?.feature ?? null;
    this.selectedFeature?.setStyle(gisStyles.selectedFeatures);
    if (!this.selectedFeature) {
      return;
    }

    const title = feature.layer.get('title');
    const translate = title === 'Building Layer' ? 'BUILDING_LAYER' : '';
    this.translatePrefix = translate ? `${translate}.` : '';
  }

  public getProperties(feature: FeatureLayerInfo) {
    const properties = { ...feature.feature.getProperties() };
    delete properties['geometry'];

    return properties;
  }

  public onOpenBuildingInfo() {
    this.openBuildingInfo.emit();
  }

  public onEditHub(feature: FeatureLayerInfo) {
    const hub = this.hubs.find(
      (h) => h.hubGuid === feature.feature.get('hub_id'),
    );

    if (!hub) {
      return;
    }

    this.hubsService.edit(hub.hubGuid, this.viewContainerRef);
  }
}
