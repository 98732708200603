import { AsyncPipe, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BannerComponent } from '../banner/banner.component';
import { BannerService } from '../banner.service';

@Component({
  selector: 'sympheny-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgFor, BannerComponent],
})
export class BannerListComponent {
  public readonly banners$ = this.bannerService.banners$;
  constructor(public readonly bannerService: BannerService) {}
}
