import {
  Component,
  Inject,
  Input,
  Optional,
  ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DB_TYPES } from '@sympheny/database/model';
import { ProjectVersion } from '@sympheny/project/data-access';
import { DataKey, ScenarioStore } from '@sympheny/project/scenario/data-access';
import { PlanLimitation, UserState } from '@sympheny/user/data-access';
import { PlanService } from '@sympheny/user/plan';

import { SaveToDbService } from './save-to-db.service';
import { SaveTechControl } from '../save-to-db-checkbox/save-to-db-checkbox.component';

export interface AbstractSaveFromScenario<T> {
  scenarioId: string;
  fromDb?: boolean;
  projectVersion?: ProjectVersion;
  isEditing?: true;
  data?: T;
  target?: DB_TYPES | 'ewz';
}

export interface AbstractSaveFromDbData<T> {
  fromDb: true;
  isEditing: true;
  data: T;
  target: DB_TYPES | 'ewz';
}

export type AbstractSaveToDbData<T> =
  | AbstractSaveFromScenario<T>
  | AbstractSaveFromDbData<T>;

export interface SaveToDb<T> {
  editing: boolean;
  suggested: boolean;
  target: DB_TYPES | 'ewz';
  data: T;
  projectVersion: ProjectVersion;
}

@Component({
  selector: 'sympheny-abstract-save-to-db',
  templateUrl: './abstract-save-to-db.component.html',
  standalone: false,
})
export abstract class AbstractSaveToDbComponent<
  T,
  DATA extends AbstractSaveToDbData<T>,
> {
  public readonly isWarmUpPlan = this.planService.isWarmUp();
  public readonly superuser = this.userState.isSu();
  public isEditing: boolean;
  public dbEdit: boolean;

  public projectVersion: ProjectVersion;

  private _data: DATA;

  @Input() public set data(data: DATA) {
    this._data = data;

    if (data) {
      this.isEditing = this.data.isEditing;
      this.dbEdit = this.isEditing && this.data.fromDb;
      this.projectVersion = (
        this.data as AbstractSaveFromScenario<T>
      ).projectVersion;
    }
  }

  public get data() {
    return this._data;
  }

  constructor(
    protected readonly userState: UserState,
    protected readonly dialogRef: MatDialogRef<unknown>,
    protected readonly saveToDbService: SaveToDbService<T>,
    protected readonly planService: PlanService,
    protected readonly scenarioStore: ScenarioStore,
    protected readonly viewContainerRef: ViewContainerRef,

    @Optional()
    @Inject(MAT_DIALOG_DATA)
    data?: DATA,
  ) {
    this.data = data;
  }
  public get saveToDbChecked() {
    return Object.values(this.saveTechControl()?.value)?.some((v) => v);
  }
  protected abstract saveTechControl(): SaveTechControl;
  protected isSaveToDb() {
    const { ewz, ewz_suggest, db, db_suggest, user } =
      this.saveTechControl().value;

    return this.dbEdit || ewz || ewz_suggest || db || db_suggest || user;
  }

  protected saveToDb(saveData: T) {
    const { ewz, ewz_suggest, db, db_suggest, user } =
      this.saveTechControl().value;

    const data = this.data as AbstractSaveFromDbData<T>;
    const target =
      data.target ??
      (ewz || ewz_suggest ? 'ewz' : db || db_suggest ? 'database-org' : 'user');
    // Check if hubs is needed in this case, if db save then it shuould not

    this.saveToDbService.saveToDb(
      {
        data: {
          ...data.data,
          ...saveData,
          suggested: ewz_suggest || db_suggest,
        },
        editing: this.dbEdit,
        suggested: ewz_suggest || db_suggest,
        target,
        projectVersion: this.projectVersion || 'V1',
      },
      this.viewContainerRef,
    );
    this.dialogRef.close();
  }

  protected submit<D extends T>(params: {
    data: D;
    guid?: string;
    planLimit?: keyof PlanLimitation;
    testLimitations?: T[];
    messagePrefix: string;
    key: DataKey;
  }) {
    const planReached = this.planService.isPlanLimitReached(
      params.planLimit,
      params.testLimitations,
    );

    if (planReached) {
      return;
    }
    this.save(params);
  }

  private save(params: {
    data: T;
    guid?: string;
    planLimit?: keyof PlanLimitation;
    messagePrefix: string;
    key: DataKey;
  }) {
    if (this.dbEdit) {
      this.saveToDb(params.data);
    } else {
      if (!this.isEditing) {
        const onSuccess = () => {
          this.dialogRef.close({
            message: `${params.messagePrefix}.added`,
          });
        };

        this.scenarioStore.create(params.key, params.data, {
          reloadHubDiagram: true,
          onSuccess,
        });

        if (this.isSaveToDb()) {
          this.saveToDb(params.data);
        }
      } else {
        const onSuccess = () => {
          this.dialogRef.close({
            message: `${params.messagePrefix}.edited`,
          });

          // Reload is needed because guid changes
          this.scenarioStore.reload(params.key);
        };
        this.scenarioStore.update(params.key, params.guid, params.data, {
          reloadHubDiagram: true,
          onSuccess,
        });

        if (this.isSaveToDb()) {
          this.saveToDb(params.data);
        }
      }
    }
  }
}
