import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoPipe } from '@ngneat/transloco';

import { ButtonSize } from '../button-size';

@Component({
  selector: 'sympheny-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  imports: [MatButtonModule, MatIconModule, MatTooltipModule, TranslocoPipe],
})
export class IconButtonComponent {
  @Input() public color: ThemePalette | 'custom' = 'primary';
  @Input() public disabled = false;
  @Input() public tooltip = '';
  @Input() public tooltipParams = {};
  @Input() public size: ButtonSize = 'regular';
  @Input() public icon = '';
  @Input() public dataCy: string;
  @Input() public fontSet: string;

  @Output() public readonly action = new EventEmitter<void>();
}
