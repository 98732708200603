import {
  CdkPortalOutlet,
  ComponentPortal,
  PortalModule,
  TemplatePortal,
} from '@angular/cdk/portal';
import {
  DatePipe,
  DecimalPipe,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoPipe } from '@ngneat/transloco';

import {
  TABLE_CELL_TOKEN,
  TableAction,
  TableColumn,
  TableColumnComponent,
  TableColumnTemplate,
} from '../model/table-configuration';
import { TableActionComponent } from '../table-action/table-action.component';

@Component({
  selector: 'sympheny-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PortalModule,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgFor,
    MatTooltipModule,
    NgSwitchDefault,
    TableActionComponent,
    DatePipe,
    TranslocoPipe,
  ],
  providers: [DecimalPipe],
})
export class TableCellComponent<T> implements OnChanges {
  @Input() public column!: TableColumn<T>;
  @Input() public element!: T;
  @Input() public rowIndex: number;
  @ViewChild(CdkPortalOutlet, { static: true })
  public portalOutlet!: CdkPortalOutlet;

  public maxValues = 5;
  public value: any;
  public translatePrefix = '';
  public type:
    | 'array'
    | 'template'
    | 'component'
    | 'datetime'
    | 'number'
    | 'integer'
    | 'translate'
    | '' = '';
  public tooltip = '';
  public action: TableAction<T> | null = null;

  constructor(
    private readonly injector: Injector,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly decimalPipe: DecimalPipe,
  ) {}

  public ngOnChanges() {
    if (!this.column || !this.element) {
      this.value = null;
      return;
    }

    this.detachPortal();

    if (this.column.type === 'template') {
      this.createTemplate(this.column as TableColumnTemplate<T>);
      this.type = 'template';
      return;
    }
    if (this.column.type === 'component') {
      this.createPortal(this.column as TableColumnComponent<T>);
      this.type = 'component';
      return;
    }

    if (this.column.type === 'translate') {
      this.translatePrefix = this.column.translatePrefix ?? '';
    }

    this.setValue();
    this.setAction();
  }

  private setAction() {
    this.action = null;
    if (this.column.type === 'action' && 'action' in this.column) {
      if ('readOnly' in this.column) {
        this.action = this.column.readOnly?.(this.element)
          ? null
          : this.column.action;
      } else {
        this.action = this.column.action;
      }
    }
  }

  private setValue() {
    let value: any = this.element[this.column.key as keyof T];

    if (value && Array.isArray(value)) {
      this.type = 'array';
      this.maxValues = this.column.maxValues ?? 5;
      this.value = value.map((v: any) => {
        const columnValue = this.column.childKey ? v[this.column.childKey] : v;

        return this.formatValue(columnValue ?? this.column.defaultValue);
      });
      this.tooltip = this.value.join(' || ');
    } else {
      if (value && this.column.childKey) {
        value = value[this.column.childKey];
      }

      this.value = this.formatValue(value ?? this.column.defaultValue);
    }
  }

  private formatValue(value: any) {
    if (value === undefined || value === null) {
      return value;
    }

    this.type = this.column.type as any;

    if (typeof value === 'number') {
      const type = this.type === 'integer' ? 'integer' : 'number';
      if (this.type !== 'array') {
        this.type = 'number';
      }
      return this.decimalPipe.transform(
        value,
        type === 'integer' ? '1.0' : '1.2',
      );
    }

    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    return value;
  }

  private createPortal({ component, data, token }: TableColumnComponent<T>) {
    const portalInjector = Injector.create({
      providers: [
        {
          provide: token ?? TABLE_CELL_TOKEN,
          useValue: { element: this.element, data },
        },
      ],
      parent: this.injector,
    });

    this.portalOutlet.attach(
      new ComponentPortal(component, null, portalInjector),
    );
  }

  private createTemplate({ template }: TableColumnTemplate<T>) {
    if (!template) {
      console.warn('template not found');
      this.detachPortal();
      return;
    }

    this.portalOutlet.attach(
      new TemplatePortal(template, this.viewContainerRef, {
        $implicit: this.element,
      }),
    );
  }

  private detachPortal() {
    if (this.hasAttachedPortal) {
      this.portalOutlet.detach();
    }
  }

  private get hasAttachedPortal(): boolean {
    return this.portalOutlet && this.portalOutlet.hasAttached();
  }
}
