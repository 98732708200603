import { Injectable, OnDestroy } from '@angular/core';
import * as ol from 'ol';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import Interaction from 'ol/interaction/Interaction';
import { Vector as VectorLayer } from 'ol/layer';
import { Options } from 'ol/layer/BaseVector';
import { Vector as VectorSource } from 'ol/source';

import { gisStyles } from './gisStyles';

export interface GisChangeEvent {
  geoJson: any | null;
}

@Injectable()
export abstract class MapFeatureService<
  ChangeEvent extends GisChangeEvent,
  INTERACTION extends Interaction,
> implements OnDestroy
{
  protected tempLayerOptions: Options<any> = {};
  protected currentChangeEvent: ChangeEvent;
  protected interaction: INTERACTION;
  private changeEvent?: (change: ChangeEvent) => void;

  protected map!: ol.Map;
  protected tempSource: VectorSource<any> | null = null;
  protected tempLayer: VectorLayer<any> | null = null;

  protected abstract defaultValue(): ChangeEvent;

  protected abstract addInteraction(): INTERACTION | null;

  public ngOnDestroy(): void {
    if (this.map && this.tempLayer) {
      this.map.removeLayer(this.tempLayer);
    }

    if (this.map && this.interaction) {
      this.map.removeInteraction(this.interaction);
    }
  }

  public init(map: ol.Map, changeEvent: (change: ChangeEvent) => void): void {
    this.resetToDefault();
    this.map = map;
    this.changeEvent = changeEvent;

    this.createTemplayer();
    this.interaction = this.addInteraction();
  }

  protected resetToDefault(): void {
    this.currentChangeEvent = this.defaultValue();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.changeEvent && this.changeEvent(this.currentChangeEvent);
  }

  protected createTemplayer() {
    this.tempSource = new VectorSource({ format: new GeoJSON() });

    this.tempLayer = new VectorLayer({
      source: this.tempSource,
      style: gisStyles.editFeatures,
      ...this.tempLayerOptions,
      properties: {
        ...this.tempLayerOptions?.properties,
        displayInLayerSwitcher: false,
      },
    });

    // this.map.addLayer(this.tempLayer);
  }

  protected getGeoJson(features: Feature<any>[]) {
    const parser = new GeoJSON();
    return parser.writeFeaturesObject(features ?? [], {
      featureProjection: 'EPSG:3857',
    });
  }

  protected updateEvent(event: Partial<ChangeEvent>) {
    this.currentChangeEvent = { ...this.currentChangeEvent, ...event };
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.changeEvent && this.changeEvent(this.currentChangeEvent);
  }
}
