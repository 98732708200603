import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoPipe } from '@ngneat/transloco';
import { MarkdownComponent, provideMarkdown } from 'ngx-markdown';

import { DialogActionsComponent } from '../dialog-actions/dialog-actions.component';

export interface MarkdownDialogData {
  title: string;
  content: string;
}
@Component({
  selector: 'sympheny-markdown-dialog',
  imports: [DialogActionsComponent, MarkdownComponent, TranslocoPipe],
  templateUrl: './markdown-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideMarkdown()],
})
export class MarkdownDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MarkdownDialogData,
    protected readonly dialogRef: MatDialogRef<MarkdownDialogComponent>,
  ) {}
}
