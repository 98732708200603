import { NgFor } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { BaseMapConfig, OpenLayerStyles } from '@sympheny/gis/utils';

interface ConfigOption {
  label: string;
  config: BaseMapConfig;
}

@Component({
  selector: 'sympheny-change-basemap',
  templateUrl: './change-basemap.component.html',
  styleUrls: ['./change-basemap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    NgFor,
    MatOptionModule,
  ],
})
export class ChangeBasemapComponent implements OnInit {
  public readonly options: ConfigOption[] = [
    {
      label: 'Monochrome',
      config: { type: 'mapbox', style: OpenLayerStyles.monoChrome },
    },
    {
      label: 'Satellite',
      config: { type: 'mapbox', style: OpenLayerStyles.satellite },
    },
    {
      label: 'Light basemap swisstopo',
      config: { type: 'mapbox', style: OpenLayerStyles.gray },
    },
    { label: 'Open street map', config: { type: 'osm' } },
  ];
  public selectedBaseMap!: BaseMapConfig;

  @Output() public readonly changeBaseMap = new EventEmitter<BaseMapConfig>();

  public ngOnInit() {
    this.selectedBaseMap = this.options[0].config;
    this.onValueChange(this.selectedBaseMap);
  }

  public onValueChange(config: BaseMapConfig) {
    this.changeBaseMap.emit(config);
  }
}
