import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { Hub } from '@sympheny/project/data-access';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { MapUtilsService } from '../map-utils.service';

@Component({
  selector: 'sympheny-map-info',
  templateUrl: './map-info.component.html',
  styleUrls: ['./map-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe],
})
export class MapInfoComponent implements AfterViewInit {
  constructor(private readonly mapUtilsService: MapUtilsService) {}
  readonly popupHoverUid = `${uuidv4()}_popoverInfo`;
  @Input() hubs: Hub[] = [];

  info$: Observable<string | null>;

  ngAfterViewInit(): void {
    this.info$ = this.mapUtilsService.addMouseHover(this.popupHoverUid, (f) =>
      this.getText(f),
    );
  }

  private getText(f: any) {
    const hubId = f.get('hub_id');
    const hubName = hubId && this.findHub(hubId)?.hubName;
    const networkName = f.get('network_name') || f.get('network_id');

    return hubName ?? networkName;
  }

  private findHub(hubId: string) {
    return this.hubs?.find((h) => h.hubGuid === hubId);
  }
}
