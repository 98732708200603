import { NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatExpansionModule,
  MatExpansionPanel,
} from '@angular/material/expansion';
import { TranslocoPipe } from '@ngneat/transloco';
import { ToggleComponent } from '@sympheny/ui/form';

@Component({
  selector: 'sympheny-scenario-form-section-with-toggle',
  templateUrl: './form-section-with-toggle.component.html',
  imports: [
    MatExpansionModule,
    TranslocoPipe,
    NgIf,
    ToggleComponent,
    ReactiveFormsModule,
  ],
})
export class FormSectionWithToggleComponent implements ControlValueAccessor {
  @Input() public sectionTitle: string;
  @Input() public description: string;
  @ViewChild(MatExpansionPanel) public readonly panel: MatExpansionPanel;
  @ViewChild(ToggleComponent) public readonly toggle: ElementRef;
  public disabled = true;
  public expanded = false;

  public formControl = new FormControl<boolean>(false);

  constructor(
    protected ngControl: NgControl,
    private readonly cdr: ChangeDetectorRef,
  ) {
    ngControl.valueAccessor = this;
  }

  public writeValue(enabled: boolean): void {
    //throw new Error('Method not implemented.');
    this.disabled = !enabled;
    this.expanded = enabled;
    if (this.formControl.value !== enabled) this.formControl.setValue(enabled);
  }

  public registerOnChange(fn: any): void {
    // throw new Error('Method not implemented.');
  }

  public registerOnTouched(fn: any): void {
    //throw new Error('Method not implemented.');
  }

  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

  public enableToggle(expanded: boolean) {
    if (this.ngControl.control.value === expanded) return;

    this.ngControl.control?.setValue(expanded);
    if (expanded)
      window.setTimeout(() => {
        this.panel.open();
      });
  }
}
