import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { LangDefinition } from '@ngneat/transloco';
import { IconButtonComponent } from '@sympheny/ui/button';

@Component({
  selector: 'sympheny-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
  imports: [IconButtonComponent, MatMenuModule, NgFor, NgIf],
})
export class LanguageSwitcherComponent {
  @Input() public languages: LangDefinition[];
  @Input() public currentLanguage: string;

  @Output() public readonly changeLanguage = new EventEmitter<string>();
}
