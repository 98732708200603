import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconButtonComponent } from '@sympheny/ui/button';

import { TableAction } from '../model/table-configuration';

@Component({
  selector: 'sympheny-table-action',
  templateUrl: './table-action.component.html',
  styleUrls: ['./table-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconButtonComponent],
})
export class TableActionComponent<T> {
  @Input() public action!: TableAction<T>;
  @Input() public element!: T;
  @Input() public index: number;

  public get disabled() {
    return (
      this.action.disabled ?? this.action.readOnly?.(this.element) ?? false
    );
  }

  public get hidden() {
    return this.action.hidden?.(this.element) ?? false;
  }
}
