import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';

import { InputSuffixComponent } from '../input-suffix/input-suffix.component';
import { OptionValue } from '../model/option-value.model';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'sympheny-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  imports: [
    TranslocoPipe,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    InfoButtonComponent,
    MatInputModule,
    NgIf,
    MatAutocompleteModule,
    NgFor,
    AsyncPipe,
    InputSuffixComponent,
  ],
})
export class AutocompleteComponent<T>
  extends SelectComponent<T>
  implements AfterViewInit, OnChanges
{
  public readonly autoCompleteFormControl = new FormControl(null);

  public readonly filteredOptions$: Observable<OptionValue<T>[]>;

  public autocompleteOptions: OptionValue<T>[] = [];

  constructor(
    ngControl: NgControl,
    private readonly translocoService: TranslocoService,
  ) {
    super(ngControl);
    this.filteredOptions$ = this.autoCompleteFormControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );

    this.autoCompleteFormControl.valueChanges
      .pipe(
        debounceTime(300),
        tap((newValue) => {
          const value = newValue?.value ?? newValue;

          if (this.formControl.value === value) {
            return;
          }
          this.formControl?.patchValue(value);
        }),
      )
      .subscribe();
  }

  public displayFn() {
    return (option: any) => {
      return option?.label;
    };
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      this.autocompleteOptions =
        this.options?.map((option) => ({
          label: this.translateOptions
            ? this.translocoService.translate(this.getLabel(option))
            : this.getLabel(option),
          value: this.getValue(option),
        })) ?? [];
    }
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  public override writeValue(value: any): void {
    super.writeValue(value);
    const newValue =
      this.autocompleteOptions.find((v) => v.value === value) ?? value;

    const origValue =
      this.autoCompleteFormControl.value?.value ??
      this.autoCompleteFormControl.value;
    if (origValue === newValue) {
      return;
    }
    this.autoCompleteFormControl.setValue(newValue, {});
  }

  private _filter(value): OptionValue<T>[] {
    const v = value.label ?? value;
    if (!v) {
      return this.autocompleteOptions;
    }
    const filterValue = v.toLowerCase();

    return this.autocompleteOptions.filter((option) =>
      option.label.toLocaleLowerCase().includes(filterValue),
    );
  }
}
