import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import {
  DataKey,
  ScenarioState,
  ScenarioStore,
} from '@sympheny/project/scenario/data-access';
import { InfoButtonComponent, ButtonComponent } from '@sympheny/ui/button';
import { LoaderComponent } from '@sympheny/ui/loader';
import { Observable, of } from 'rxjs';

import { CrudListActionComponent } from '../crud-list-action/crud-list-action.component';

@Component({
  selector: 'sympheny-crud-list',
  templateUrl: './crud-list.component.html',
  styleUrls: ['./crud-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    InfoButtonComponent,
    ButtonComponent,
    LoaderComponent,
    NgFor,
    CrudListActionComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
/**
 * @deprecated use CrudTableComponent, so everything looks similar
 */
export class CrudListComponent<ITEM> {
  @Input() public subject: DataKey | string;
  @Input() public items: ITEM[];
  @Input() public key: keyof ITEM;
  @Input() public tooltip: string;
  @Input() public disableEdit: boolean;
  @Input() public subText?: (item: ITEM) => void;

  @Output() public readonly addItem = new EventEmitter<void>();
  @Output() public readonly editItem = new EventEmitter<ITEM>();
  @Output() public readonly deleteItem = new EventEmitter<ITEM>();

  public readonly disabled$ = this.scenarioState.isLocked$;
  public readonly readonly$ = this.scenarioState.readonly$;
  public readonly canEdit$ = this.scenarioState.canEdit$;
  public loading$: Observable<boolean> = of(false);

  constructor(private readonly scenarioState: ScenarioStore) {}

  @Input() public set scenarioStoreField(field: keyof ScenarioState) {
    if (!field) {
      return;
    }
    this.loading$ = this.scenarioState.isLoading(field);
  }
}
