import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
  selector: 'sympheny-dialog-actions',
  templateUrl: 'dialog-actions.component.html',
  styleUrls: ['./dialog-actions.component.scss'],
  imports: [MatButtonModule, TranslocoPipe, MatDialogModule, NgIf],
})
export class DialogActionsComponent {
  @Input() public disabled = false;
  @Input() public submitLabel = 'Submit';
  @Input() public submitColor = 'primary';
  @Input() public cancelLabel = 'Cancel';
  @Input() public hideSubmit = false;
  @Input() public hideCancel = false;

  @Output() public readonly submitAction = new EventEmitter<void>();
}
